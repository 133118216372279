import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: TJ Whitelaw"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="TJ Whitelaw"
        videoUrl="https://vimeo.com/498103016/ba63bcf57a"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.tjWhitelawImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              TJ Whitelaw
            </Text>
            <Text level="1" mb="1.2rem">
              Touring and Session Musician
            </Text>
            <Box>
              <Link to="https://www.instagram.com/tjwhitelaw/" as="a">
                <Instagram />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              TJ Whitelaw is a Toronto-based touring and session musician. He
              has performed on 3 of the biggest network TV stations in Canada,
              <strong>CBC, CTV</strong> and <strong>Global</strong> as well as
              performing on <strong>Mahogany Sessions</strong> (2019 w/{" "}
              <strong>Amaal</strong>),{" "}
              <strong>The Directors Guild of Canada Awards</strong> (2019), the{" "}
              <strong>Indspire Awards</strong> (national aboriginal awards), in
              the house band for <strong>Canada’s Walk of Fame</strong> etc. as
              well as multiple <strong>IHeart </strong>radio stations
              nation-wide.
            </Text>
            <Text level="2">
              Tj’s on-stage experience has been vast (touring in North America,
              Europe and Asia), and wide-ranging that spans from Pop/country to
              jazz to Gospel. He’s performed with{" "}
              <strong>
                MYA, Jessie Reyez, Madison Beer, Kardinal Offishall, Allan
                Rayman, Ruben Studdard, Carvin Winans, Johnny Reid, Sean Jones
              </strong>{" "}
              and many more. Currently Tj is performing with{" "}
              <strong>Aja9</strong> (Musical Director), <strong>Amaal</strong>{" "}
              (Musical Director), <strong>JP Saxe</strong>, and{" "}
              <strong>LIZA</strong>.
            </Text>
            <Text level="2">
              Beside being a live musician, Tj also has a multitude of
              collaborations and session work, ranging from Canto-pop to Gospel
              some of these artists include,{" "}
              <strong>
                Alfred Hui, Jp Saxe, Billy Davis, Kiana Valenciano, Emma-Lee,
                LordQuest, Junia-T,
              </strong>{" "}
              and many more.
            </Text>
          </Box>
          
        </Box>
      </Width>
      <MentorFooter data={data} name="Curt Henderson" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    tjWhitelawImage: file(
      relativePath: { eq: "tjWhitelaw-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
